import React from 'react'

const Header = () => {
    return (

        <h1 className="h1m">Expense Tracker By Mubashar</h1>

    )
}

export default Header
